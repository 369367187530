import { router } from "@inertiajs/react"
import { useState } from "react"

import { Collapse, List } from "@mui/material"

import { MenuListItem, SidebarItem } from "./MenuListItem"

interface Props {
  title: string
  icon: React.ReactNode
  textColor: string
  items: SidebarItem[]
  userRoles: string[]
  requiredUserRoles: string[]
  isExpanded?: boolean
}
export const MenuSection = ({
  title,
  icon,
  textColor,
  items,
  userRoles,
  isExpanded = false,
  requiredUserRoles = [],
}: Props) => {
  const [expanded, setExpanded] = useState(isExpanded)
  const activeSite = new URL(window.location.href).pathname

  const clickElement = (href: string) =>
    href === "/logout" ? router.post(href) : router.get(href)
  const isItemActive = (redirectTo: string) => {
    return (
      activeSite === redirectTo ||
      (activeSite.startsWith(redirectTo) && redirectTo !== "/")
    )
  }

  return (
    <>
      <MenuListItem
        item={{ title, icon, requiredUserRoles }}
        userRoles={userRoles}
        isActive={isExpanded}
        textColor={textColor}
        onClick={() => setExpanded(!expanded)}
        isCollapsable
        collapsed={expanded}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="div" sx={{ pl: 1 }}>
          {items.map((item, index) => (
            <MenuListItem
              key={index}
              item={item}
              userRoles={userRoles}
              isActive={isItemActive(item.redirectTo || "")}
              textColor={textColor}
              onClick={() => clickElement(item.redirectTo as string)}
            />
          ))}
        </List>
      </Collapse>
    </>
  )
}
