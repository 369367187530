import { MdExpandLess, MdExpandMore } from "react-icons/md"

import { ListItem, ListItemIcon, ListItemText } from "@mui/material"
import { useTheme } from "@mui/material"

export interface SidebarItem {
  title: string
  icon: React.ReactNode
  requiredUserRoles: string[]
  color?: string
  redirectTo?: string
}

interface Props {
  item: SidebarItem
  isActive: boolean
  textColor: string
  userRoles: string[]
  onClick?: () => void
  isCollapsable?: boolean
  collapsed?: boolean
}

export const MenuListItem = ({
  item,
  isActive,
  textColor,
  onClick,
  userRoles,
  isCollapsable = false,
  collapsed = false,
}: Props) => {
  const theme = useTheme()

  const hasPermissions = () => {
    if (!item.requiredUserRoles) return true
    return userRoles.some(role => item.requiredUserRoles.includes(role))
  }

  return (
    <ListItem
      onClick={onClick}
      onAuxClick={event => {
        if (item.redirectTo && event.button === 1) {
          window.open(item.redirectTo, "_blank")
        }
      }}
      sx={{
        width: "100%",
        display: hasPermissions() ? "flex" : "none",
        background: isActive
          ? "#e3f4ff"
          : "linear-gradient(180deg, #C0C5CB 0%, #FFFFFF 100%)",
        boxShadow: theme.shadows[4],
        borderRadius: 2,
        p: "2px",
        "&:hover": {
          boxShadow: theme.shadows[7],
          transition: "box-shadow 0.3s ease-in-out",
          background: "linear-gradient(180deg, #FFFFFF 0%, #C0C5CB 100%)",
        },
        mb: "5px",
        cursor: "pointer",
      }}>
      <ListItemIcon sx={{ pl: 2 }}>{item.icon}</ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ sx: { fontWeight: 600 } }}
        sx={{ color: item.color ? item.color : textColor }}
        primary={item.title}
      />
      {isCollapsable ? collapsed ? <MdExpandLess /> : <MdExpandMore /> : null}
    </ListItem>
  )
}
