import logo from "@/../assets/logo.png"
import { router } from "@inertiajs/react"

import { Box } from "@mui/system"

const MenuLogo = () => (
  <Box
    display="flex"
    sx={{ borderBottom: 1, borderColor: "divider", cursor: "pointer" }}
    onClick={() => router.get("/")}
    onAuxClick={event => {
      if (event.button === 1) {
        window.open("/", "_blank")
      }
    }}>
    <img
      src={logo}
      style={{
        marginRight: "auto",
        marginLeft: "auto",
        width: "120px",
      }}
    />
  </Box>
)

export default MenuLogo
