import { router } from "@inertiajs/core"
import { usePage } from "@inertiajs/react"
import { MdBook, MdFactory, MdReport } from "react-icons/md"

import { MenuListItem } from "@/Components/MenuItem/MenuListItem"
import { MenuSection } from "@/Components/MenuItem/MenuSection"

import { dictionaires, menuItems, reports, warehouses } from "./menuItems"

const clickElement = (href: string) =>
  href === "/logout" ? router.post(href) : router.get(href)

const MenuButtons = () => {
  // @ts-ignore
  const roles: string[] = usePage()?.props?.roles || []
  const activeSite = new URL(window.location.href).pathname

  const isItemActive = (redirectTo: string) => {
    return (
      activeSite === redirectTo ||
      (activeSite.startsWith(redirectTo) && redirectTo !== "/")
    )
  }

  return (
    <>
      <div style={{ height: "80%", overflowY: "auto", padding: 10 }}>
        <MenuSection
          title="Słowniki"
          icon={<MdBook color={"black"} />}
          items={dictionaires}
          isExpanded={false}
          textColor="black"
          userRoles={roles}
          requiredUserRoles={["ADMIN", "DT_PRACOWNIK", "DT_SPRAWDAJACY"]}
        />
        <MenuSection
          title="Magazyny"
          icon={<MdFactory color={"black"} />}
          items={warehouses}
          isExpanded={false}
          textColor="black"
          userRoles={roles}
          requiredUserRoles={[
            "ADMIN",
            "MAG_PRACOWNIK",
            "MAG_DOSTAWCA",
            "MAG_KOORDYNATOR",
          ]}
        />
        <MenuSection
          title="Raporty"
          icon={<MdReport color={"black"} />}
          items={reports}
          isExpanded={false}
          textColor="black"
          userRoles={roles}
          requiredUserRoles={["ADMIN", "DH_PRACOWNIK", "DH_SPRAWDZAJACY"]}
        />
        {menuItems.map((item, index) => (
          <MenuListItem
            key={index}
            item={item}
            isActive={isItemActive(item.redirectTo || "")}
            textColor={"black"}
            onClick={() => clickElement(item.redirectTo as string)}
            userRoles={roles}
          />
        ))}
      </div>
    </>
  )
}

export default MenuButtons
